import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-dropdown-select";
import axios from "axios";
import Cookies from "js-cookie";

function ProductModal(props) {
  const { setShow, show } = props;
  const [product, setProduct] = useState({});
  const images = useRef([]);
  const [files, setFiles] = useState({});
  const [Brandoptions, setBrandOptions] = useState([]);

  const Categoryoptions = [
    { label: "Smartphones", value: 1 },
    { label: "Ordenadores", value: 2 },
    { label: "Smartwatch", value: 3 },
    { label: "Tablets", value: 3 },
  ];

  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    setProduct((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  // Fetch brands from the API
  const fetchBrands = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/admin/brands"
      );
      const options = response.data.map((brand) => ({
        label: brand.name,
        value: brand.name,
      }));
      setBrandOptions(options);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  // Add a new brand
  const addNewBrand = async () => {
    const newBrand = window.prompt("Add New Brand:");

    console.log(newBrand);
    
    if (newBrand) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL + "/api/admin/add",
          { name: newBrand }
        );
        alert("Brand added successfully!");
        fetchBrands(); // Refresh brand list
      } catch (error) {
        console.error("Error adding brand:", error);
        alert("Failed to add brand");
      }
    }
  };

  const PostMultipleFile = async () => {
    let formdata = new FormData();

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      formdata.append("files", file);
    }

    try {
      const result = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/uploadImg",
        formdata
      );
      images.current = result.data.map((elem) => elem.path);
    } catch (err) {
      console.log(err.data);
    }
  };

  const addProduct = async () => {
    await PostMultipleFile();
    const newProduct = { ...product, images: images.current };

    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/admin/addproduct",
        newProduct,
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      props.FetchProducts();
      handleClose();
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    fetchBrands(); // Fetch brands when the modal opens
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      className="ProductModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form method="post" encType="multipart/form-data">
          <label>Product Images</label>
          <input
            type="file"
            name="files"
            id="images"
            className="form-control mt-1"
            onChange={(e) => setFiles(e.target.files)}
            multiple
          />
        </form>
        <div className="mt-2">
          <label>Product Title</label>
          <input
            type={"text"}
            placeholder="Product title"
            id="title"
            className="form-control mt-1"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="mt-2">
          <label>Product Description</label>
          <textarea
            type={"text"}
            id="description"
            placeholder="Product description"
            onChange={(e) => handleChange(e)}
            className="form-control mt-1"
          />
        </div>
        <div className="mt-2">
          <label>Category</label>
          <Select
            className="input rounded"
            options={Categoryoptions}
            onChange={(e) =>
              setProduct({ ...product, category: e[0]?.label })
            }
          />
        </div>
        <div className="mt-2">
          <label>Brand</label>
          <div className="d-flex align-items-center gap-3 col-12">
            <div className="col-md-8">
              <Select
                className="input rounded "
                options={Brandoptions}
                onChange={(e) =>
                  setProduct({ ...product, brand: e[0]?.label })
                }
              />
            </div>
            <Button className="mt-2" style={{ fontSize: 14 }} onClick={addNewBrand}>
              Add New Brand
            </Button>
          </div>
        </div>
    
        <div className="mt-2">
          <label>Stock</label>
          <input
            type={"number"}
            id="stock"
            placeholder="Stock"
            onChange={(e) => handleChange(e)}
            className="form-control mt-1"
          />
        </div>
        <div className="mt-2">
          <label>Price</label>
          <input
            type={"number"}
            id="price"
            placeholder="Price"
            onChange={(e) => handleChange(e)}
            className="form-control mt-1"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            addProduct();
          }}
        >
          Publish
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProductModal;
