import axios from "axios";
import Cookies from "js-cookie";
import React, { useState, useRef } from "react";
import { Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import EditModal from "./EditModal";

const Tablee = (props) => {
  const [modal, setModal] = useState(false);
  const updateData = useRef(null);
  const { data, FetchProducts } = props;

  const handleDelete = async (id) => {
    const confirmm = window.confirm("Are you sure?");
    if (confirmm) {
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/admin/deleteProduct/${id}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
            },
          }
        );
        FetchProducts()
      } catch (err) {
        console.log(err.response);
      }
    }
  };

  const handleUpdate = async (elem) => {
    setModal(true);
    updateData.current = elem;
  };


  return (
    <div>
      <h1 className="fw-bold fs-5">{props.title}</h1>
      <Table responsive className="Tablee mt-3">
        <thead>
          <tr>
            <th>Image</th>
            <th>Title</th>
            <th>Category</th>
            <th>Stock</th>
            <th>Price</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((elem) => {
            return (
              <tr>
                <td>
                  <img
                    src={process.env.REACT_APP_BASE_URL + "/" + elem.images[0]}
                    alt="img"
                    width={"60px"}
                  />
                </td>
                <td>{elem.title}</td>
                <td>{elem.category}</td>
                <td>{elem.stock}</td>
                <td>{elem.price}</td>
                <td>Available</td>
                <td>
                  <div className="d-flex">
                    <FiEdit
                      className="me-2 active"
                      size={22}
                      color={"gray"}
                      cursor={"pointer"}
                      onClick={() => handleUpdate(elem)}
                    />
                    <AiOutlineDelete
                      size={22}
                      color={"gray"}
                      cursor={"pointer"}
                      className="text-danger"
                      onClick={() => handleDelete(elem._id)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {modal && <EditModal setShow={setModal} show={modal} data={updateData} FetchProducts={FetchProducts} />}
    </div>
  );
};

export default Tablee;
