import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import "./customer.css";

const Customers = () => {
  const [data, setData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const FetchUsers = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_BASE_URL + "/api/admin/getusers",
          { headers: { token: Cookies.get("token") } }
        );
        setData(res.data.users);
      } catch (error) {
        console.log(error);
      }
    };
    FetchUsers();
  }, []);

  const handleViewDetails = (customer) => {
    setSelectedCustomer(customer);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCustomer(null);
  };

  return (
    <div className="content">
      <div className="Products">
        <h1 className="fs-5 fw-bold">Customers</h1>
        <div className="searchBox">
          <input
            type={"text"}
            placeholder="Search By Name"
            className="form-control input rounded"
          />
        </div>

        <Table responsive className="Tablee mt-3">
          <thead>
            <tr>
              <th>Profile</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>City</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((elem) => {
              return (
                <tr key={elem._id}>
                  <td className="text-center">
                    {elem?.img?.length > 0 ? (
                      <img
                        src={process.env.REACT_APP_BASE_URL + "/" + elem.img}
                        alt="img"
                        width={100}
                      />
                    ) : (
                      <BiUserCircle size={30} className="gray" />
                    )}
                  </td>
                  <td>{elem.name}</td>
                  <td>{elem.email}</td>
                  <td>{elem.phone}</td>
                  <td>{elem.city}</td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="info"
                        size="sm"
                        onClick={() => handleViewDetails(elem)}
                      >
                        View Details
                      </Button>
                      <AiOutlineDelete
                        size={23}
                        cursor={"pointer"}
                        className="text-danger fw-bold ms-2"
                      // onClick={() => handleDelete(elem._id)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Modal for showing customer details */}
      <Modal show={showModal} onHide={handleCloseModal} backdrop="static"> 
        <Modal.Header closeButton>
          <Modal.Title>Customer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCustomer ? (
            <div>
              <p><strong>Name:</strong> {selectedCustomer.name} {selectedCustomer.surname}</p>
              <p><strong>Email:</strong> {selectedCustomer.email}</p>
              <p><strong>Phone:</strong> {selectedCustomer.address?.phone || "N/A"}</p>
              <p><strong>City:</strong> {selectedCustomer.address?.city || "N/A"}</p>
              <p><strong>State:</strong> {selectedCustomer.address?.state || "N/A"}</p>
              <p><strong>Street:</strong> {selectedCustomer.address?.street || "N/A"}</p>
              <p><strong>Zip:</strong> {selectedCustomer.address?.zip || "N/A"}</p>
              <p><strong>UID:</strong> {selectedCustomer.uid}</p>
              <p><strong>Registration Date:</strong> {new Date(selectedCustomer.date).toLocaleString()}</p>
            </div>
          ) : (
            <p>Loading details...</p>
          )}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Customers;
