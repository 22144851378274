import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";

const Order = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    const FetchUsers = async () => {
      try {
        const { data } = await axios.get(
          process.env.REACT_APP_BASE_URL + "/api/orders/all",
          { headers: { token: Cookies.get("token") } }
        );
        setData(data);
      } catch (error) {
        console.log(error);
      }
    };
    FetchUsers();
  }, []);

  const handleShowModal = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOrder(null);
  };

  return (
    <div className="content">
      <div className="Products">
        <h1 className="fs-5 fw-bold">Orders</h1>

        <Table responsive className="Tablee mt-3">
          <thead>
            <tr>
              <th>Profile</th>
              <th>Name</th>
              <th>Email</th>
              <th>Bill</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((elem) => {
              return (
                <tr key={elem._id}>
                  <td className="text-center">
                    {elem?.img?.length > 0 ? (
                      <img
                        src={process.env.REACT_APP_BASE_URL + "/" + elem.img}
                        alt="img"
                        width={100}
                      />
                    ) : (
                      <BiUserCircle size={30} className="gray" />
                    )}
                  </td>
                  <td>{elem?.userId?.name}</td>
                  <td>{elem?.userId?.email}</td>
                  <td>{elem.bill}</td>
                  <td>
                    <div className="d-flex">
                      <Button
                        variant="info"
                        onClick={() => handleShowModal(elem)}
                      >
                        View Details
                      </Button>
                      <AiOutlineEdit
                        size={23}
                        cursor={"pointer"}
                        className="text-primary fw-bold ms-2"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder ? (
            <div>
              <p><strong>Name:</strong> {selectedOrder?.userId?.name}</p>
              <p><strong>Email:</strong> {selectedOrder?.userId?.email}</p>
              <p><strong>Bill:</strong> ${selectedOrder.bill}</p>
              <p><strong>Status:</strong> {selectedOrder.status}</p>
              <p><strong>Address:</strong></p>
              <ul>
                <li>Street: {selectedOrder.address?.street}</li>
                <li>City: {selectedOrder.address?.city}</li>
                <li>State: {selectedOrder.address?.state}</li>
                <li>Zip: {selectedOrder.address?.zip}</li>
                <li>Phone: {selectedOrder.address?.phone}</li>
                <li>ID Number: {selectedOrder.address?.idnumber}</li>
              </ul>
              <p><strong>Products:</strong></p>
              <ul>
                {selectedOrder.products?.map((product, index) => (
                  <li key={index}>
                    {product.name} - ${product.price} x {product.quantity}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <p>No details available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Order;
