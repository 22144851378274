import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-dropdown-select";
import axios from "axios";
import Cookies from "js-cookie";

function EditModal(props) {
  const { setShow, show, data } = props;
  const [product, setProduct] = useState({});
  const images = useRef([]);
  const [files, setFiles] = useState({});
  
  const Categoryoptions = [
    { label: "Smartphones", value: 1 },
    { label: "Ordenadores", value: 2 },
    { label: "Smartwatch", value: 3 },
    { label: "Tablets", value: 3 },
  ];


  const handleClose = () => setShow(false);

  const PostMultipleFile = async () => {
    let formdata = new FormData();

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      formdata.append("files", file);
    }

    try {
      const result = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/uploadImg",
        formdata
      );
      images.current = result.data.map((elem) => elem.path);
    } catch (err) {
      console.log(err.data);
    }
  };

  const handleChange = (e) => {
    setProduct({ ...product, [e.target.id]: e.target.value });
  };

  const editProduct = async () => {
    Object.keys(files).length > 0 && (await PostMultipleFile());
    const newProduct = { ...product, images: images.current };

    try {
      await axios.put(
        process.env.REACT_APP_BASE_URL + `/api/admin/updateproduct/${product._id}`,
        newProduct,
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      props.FetchProducts()
      handleClose();
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    setProduct(data.current);
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      className="ProductModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form method="post" encType="multipart/form-data">
          <label>Product Images</label>
          <input
            type="file"
            name="files"
            id="images"
            className="form-control mt-1"
            onChange={(e) => setFiles(e.target.files)}
            multiple
          />
        </form>
        <div className="mt-2">
          <label>Product Title</label>
          <input
            type={"text"}
            placeholder="Product title"
            id="title"
            className="form-control mt-1"
            value={product.title}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="mt-2">
          <label>Product Description</label>
          <textarea
            type={"text"}
            id="description"
            placeholder="Product description"
            onChange={(e) => handleChange(e)}
            className="form-control mt-1"
            value={product.description}
          />
        </div>
        <div className="mt-2">
          <label>Category</label>
          <Select
            options={Categoryoptions}
            className="input rounded"
            onChange={(e) => setProduct({ ...product, category: e[0]?.label })}
            value={product.category}
          />
        </div>

        <div className="mt-2">
          <label>Stock</label>
          <input
            type={"number"}
            id="stock"
            placeholder="Stock"
            onChange={(e) => handleChange(e)}
            className="form-control mt-1"
            value={product.stock}
          />
        </div>
        <div className="mt-2">
          <label>Price</label>
          <input
            type={"number"}
            id="price"
            placeholder="Price"
            onChange={(e) => handleChange(e)}
            className="form-control mt-1"
            value={product.price}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            editProduct();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditModal;
